import { Injectable, EventEmitter } from '@angular/core';
import { Slot } from '../models/interface';
/**
 * Created by Anil Suryavanshi on 11/05/20.
 */

@Injectable({providedIn:'root'})
export class CommonService {

  public locale = 'en-US';
  callType: string = 'demo';
  call_header:string = 'Builder Scheduler'
  call_description: string = 'A calendar app for booking available slots.'
  description_color: string = '#9F4DFF';
  bookingId:number ;
  monthTimeSlot: Array<{
    date: string;
    time: Slot[];
  }> = [];
  //updateManagerDetail = new EventEmitter<any>();
  userName:string; 
  location:string;
  description_text:string
  meeting_time:string
  availabilityObject;
  start_time_in_all_timezone:string;
  formRequiredFieldConfig:{
    company: boolean;
    product_bx: boolean;
  };
  constructor() {
   
  }



  getFormatedPhone(phone: string) {
    if(phone.charAt(0)==='-'){
      return phone.substring(1); 
    } else if ((phone.charAt(0) !== '-' && phone.charAt(0) === '+' && phone.length >= 10) || (phone.length === 10)) {
      return phone.trim();
    } else if(phone.length >= 10 && phone.charAt(0) !== '+' ) {
      return '+' + phone.trim();
    } else {
      return ''
    }
    // return phone ? phone.indexOf("-") !== -1 ? `+${phone.trim()}` : phone : phone;
  }

  public humanize(str) {
    var frags = str[0].split('_');
    for (let i = 0; i < frags.length; i++) {
      let index = i;
      frags[index] = frags[index].charAt(0).toUpperCase() + frags[index].slice(1);
    }
    return frags.join(' ');
  }
}
