
<div class="bcl-main-work">
    <div class="last-screen"><!--last screen here-->
        <div class="bcl-main-heading bcl-common-heading">
            You are scheduled with {{commonService.userName}}
        </div>
        <div class="bcl-scheduled-confirm">
            <ul>
                <li>
                    <div class="bcl-common-heading">Builder.ai-{{call_label}}</div>
                </li>
                <li>
                    <div class="bcl-common-subheading bcl-purple">{{commonService.start_time_in_all_timezone}}</div>
                </li>
                <li>
                    <div class="bcl-common-subheading bcl-grey-text">Location: {{commonService.location}} 
                        <span class="copy-link" title="Copy link"
                        *ngIf="commonService.location"
                        [class.copied]="addClassCopy"
                        ngxClipboard
                        [cbContent]="commonService.location"
                        (click)="$event.stopPropagation();addClassForCopy()">
                     
                         <img src="assets/images/copy-icon.svg">
                         <div class="installmentContentTooltip">
                           <div class="instalment-content-inner">
                              <p>Link copied</p>
                           </div>
                         </div>
                       </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="bcl-common-btn-group">
    <div class="error-warning"><!--empty div for handle flex ui-->
        <div *ngIf="message" class="success-msg">{{message}}</div>
    </div>    
    <div class="bcl-btn-grp">    
        <button (click)="reInvite()" class="bcl-common-button bcl-green-btn bcl-confirm-btn">Resend Invite</button><!--for second screen "Schedule"--><!--for third screen "Resend Invite"-->
    </div>
</div>
