import { Component, OnInit } from '@angular/core';
/**
 * Created by Anil Suryavanshi on 27/05/20.
 * Invalid access component for  invalid permission
 */
@Component({
  selector: 'app-invalid-access',
  templateUrl: './invalid-access.component.html',
  styleUrls: ['./invalid-access.component.scss']
})
export class InvalidAccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
