import { Component, OnInit } from '@angular/core';
import { IAngularMyDpOptions, IMyDateModel, IMyCalendarViewChanged } from 'angular-mydatepicker';
import { SchedulerService } from 'src/app/services/scheduler.service';
import { DataService } from 'src/app/services/data.service';
import * as moment from 'moment';
import * as moment2 from 'moment-timezone';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { TimeZoneService } from 'src/app/services/timezone.service';
import { CommonService } from 'src/app/services/common.service';
import { Slot, AvailabilitySlot } from 'src/app/models/interface';
import { Angulartics2Segment } from 'angulartics2/segment';

@Component({
  selector: 'app-schedule-calender',
  templateUrl: './schedule-calender.component.html',
  styleUrls: ['./schedule-calender.component.scss']
})
export class ScheduleCalenderComponent implements OnInit {

  MONTHS: Array<String> = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  public modelDate: IMyDateModel;
  timezones: Array<any> =  moment2.tz.names();  // TimezoneList.timezone_list;    // ['IST','PDT','PST'];  
  slots: Array<Slot> = [];
  selectedDate:Date = new Date();
  selectedDateShow: string = moment(new Date()).format('dddd, MMMM DD');
  callType: string = 'spec';
  public selectTimezoneDropDown = false;
  selectedTimezone: string = moment2.tz.guess();
  selectedTimeSlot: Slot;
  validError: boolean = false;
  chooseAnotherSlot: boolean = false;
  monthTimeSlot: Array<{
    date: string;
    time: Slot[];
  }> = [];
  calendarView: Date = new Date();
  onlyOnce:boolean = true;
  public myDatePickerOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd.mm.yyyy',
    inline: true
  };
  searchTimeZone:string;
  constructor(private router: Router,
    private schedulerService: SchedulerService,
    private appDataService: DataService,
    private loaderService: LoaderService,
    private _timeZoneService: TimeZoneService,
    public angulartics2Segment: Angulartics2Segment,
    private commonService:CommonService) {
  }

  ngOnInit(): void {
    if(!this.appDataService.urlParameters.token){
      this.router.navigate(['invalid-access']);
    }
    let backButton = false;
    let d = new Date(); // Date object for Disable the calendar
    d.setDate(d.getDate() - 1);
    this.myDatePickerOptions = {
      dateRange: false,
      dateFormat: 'dd.mm.yyyy',
      disableUntil: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      },
      disableSince: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      },
      inline: true
    };
    if (this.appDataService.urlParameters && this.appDataService.urlParameters.call_type) {
      this.callType = this.appDataService.urlParameters.call_type;
    }
    // let newUTCMonth = new Date().getUTCMonth()
    // let currentMonth = this.MONTHS[newUTCMonth]

  //  let availabilityObject = sessionStorage.getItem('availabilitySlot');
    if (this.commonService.availabilityObject) {
     // let availabilityObj:AvailabilitySlot = JSON.parse(availabilityObject);
      this.selectedTimeSlot = this.commonService.availabilityObject.selectedTime;
      this.selectedDate = new Date(this.commonService.availabilityObject.selectedDate);
      this.calendarView = new Date(this.commonService.availabilityObject.selectedDate);
      this.setDateShow();
      this.selectedTimezone = this.commonService.availabilityObject.timezone;
      this._timeZoneService.currentTimeZone = this.selectedTimezone;
      console.log(this.selectedTimezone);
      //sessionStorage.removeItem('availabilitySlot');
      this.commonService.availabilityObject = null;
      backButton = true;
    }
    this.commonService.description_color= '#9B9FB1';

    this.commonService.callType  = this.callType;

    this.modelDate = {
      isRange: false,
      singleDate: {
        date: {
          year: this.selectedDate.getFullYear(),
          month: this.selectedDate.getMonth() + 1,
          day: this.selectedDate.getDate()
        }
      }

    }

    // currentMonth = this.MONTHS[this.selectedDate.getUTCMonth()];
   
    if(backButton){
      this.monthTimeSlot = this.commonService.monthTimeSlot;
      this.setCalendarDates( this.monthTimeSlot);
      this.setTimeSlots(this.selectedDate);
    }else{
      this.getInitAvailability();
      this.getSchedulerConfiguration();
    }

  }

  /**
   * @method getInitAvailability
   * @description call default
   */
  getInitAvailability() {
    let lastDates = this._timeZoneService.getMonthsLastDate();
    this.getAvailability( this.selectedTimezone, moment(this.selectedDate).format('yyyy-MM-DD'), lastDates.last);
  }

  /**
   * @method setDateShow
   * @description For setting the date format i.e. Monday, March 13
   */
  setDateShow() {
    this.selectedDateShow = moment(this.selectedDate).format('dddd, MMMM DD')
  }
  /**
   * @method calendarChange
   * @description event capturing on calendar view changes
   * @param event : IMyCalendarViewChanged
   */
  calendarChange(event: IMyCalendarViewChanged) {
    this.angulartics2Segment.eventTrack('Month Navigator Clicked', null);
   if( this.onlyOnce){
     this.onlyOnce = false;
     return;
   }
   this.selectedTimeSlot = null;
  
    // console.log('onCalendarViewChanged(): Year: ', event.year, ' - month: ', event.month, ' - first: ', event.first, ' - last: ', event.last);
    let d = new Date();
    if (event.year == d.getFullYear() && event.month > d.getMonth()+1) {
      // check if greater than current date than only fetch ;
      this.calendarView = new Date();
      this.calendarView.setFullYear(event.year, event.month-1, 1);
      let lastDates = this._timeZoneService.getMonthsLastDate(this.calendarView);
      this.getAvailability(this.selectedTimezone, lastDates.first, lastDates.last);
    }else if(event.year == d.getFullYear() && event.month == d.getMonth()+1 ){
      this.calendarView = new Date();
      this.calendarView.setFullYear(event.year, event.month-1, 1);
      let lastDates = this._timeZoneService.getMonthsLastDate(this.calendarView);
      this.getAvailability(this.selectedTimezone, moment(d).format('yyyy-MM-DD'), lastDates.last);
    }else if(event.year > d.getFullYear() ){
      this.calendarView = new Date();
      this.calendarView.setFullYear(event.year, event.month-1, 1);
      let lastDates = this._timeZoneService.getMonthsLastDate(this.calendarView);
      this.getAvailability(this.selectedTimezone, lastDates.first, lastDates.last);
    }

  }



  ngAfterViewInit() {

    
  }

  /**
   * @method onDateChange
   * @description Update the selected date on change of calendar
   * @param IMyDateModel : event date
   */
  onDateChange(event: IMyDateModel) {
    this.angulartics2Segment.eventTrack('Available Date Slot Selected', null);
    this.selectedTimeSlot = null;
    this.slots = [];
    this.selectedDate = event.singleDate.jsDate;
    let selectDate = moment(this.selectedDate);
    this.selectedDateShow = selectDate.format('dddd, MMMM DD');
    // this.calendarView.getFullYear()>this.selectedDate.getFullYear() || 
    if (this.calendarView.getMonth() == this.selectedDate.getMonth()) {
      this.setTimeSlots(this.selectedDate);
    } else {
      this.calendarView = new Date(this.selectedDate);
      let lastDates = this._timeZoneService.getMonthsLastDate(this.selectedDate);
      this.getAvailability( this.selectedTimezone, lastDates.first, lastDates.last);
    }


  }

  /**
   * @method getSchedulerConfiguration
   * @description api hit with call type for setting the configuration
   */
  getSchedulerConfiguration() {
    this.loaderService.show();
    console.log(this.callType);
    this.schedulerService.getSchedulerConfiguration(this.callType).subscribe(res => {
      if(res.data && res.data.schedulerconfiguration ){
        this.commonService.call_header = res.data.schedulerconfiguration.heading + ' - ' + (res.data.schedulerconfiguration.meeting_time? this.minHourFormat(res.data.schedulerconfiguration.meeting_time):'');
        this.commonService.call_description = res.data.schedulerconfiguration.sub_heading ;
        this.commonService.description_text = res.data.schedulerconfiguration.description_text ;
        this.commonService.meeting_time = res.data.schedulerconfiguration.meeting_time ;

        this.commonService.formRequiredFieldConfig = {} as any;
        this.commonService.formRequiredFieldConfig.company = res.data.schedulerconfiguration.company;
        this.commonService.formRequiredFieldConfig.product_bx = res.data.schedulerconfiguration.description;
      }
      
     
    }, error => {
      this.loaderService.hide();
    })
  }
  /**
   * @method getAvailability
   * @description Gets time slots 
   * @param month 
   * @param timezone 
   * @param start_date 
   * @param end_date 
   */
  getAvailability( timezone, start_date, end_date) {
    this.chooseAnotherSlot = false;
    this.loaderService.show();
    let isStore = false;
    if(this.appDataService.urlParameters.store){
      isStore = true;
    }
    this.schedulerService.getAvailability( timezone, start_date, end_date, this.callType, isStore,this.appDataService.urlParameters.email,this.appDataService.urlParameters.generic_id).subscribe(res => {
      this.monthTimeSlot = res.data;
      this.setCalendarDates( this.monthTimeSlot);
      this.commonService.monthTimeSlot =  res.data;
      this.loaderService.hide();
      this.setTimeSlots(this.selectedDate);
    }, error => {
      this.loaderService.hide();
    })
  }

  /**
   * @method timezoneChange
   * @description Updating the selected timezone
   * @param event contains the target.vale of drop down
   */
  timezoneChange(event) {
    //console.log( event.target.value)
    this.selectedTimeSlot = null;
    this.slots = [];
    this._timeZoneService.currentTimeZone = this.selectedTimezone;
    let lastDates = this._timeZoneService.getMonthsLastDate(this.calendarView);
    if(this.calendarView.getMonth() == new Date().getMonth() ){
      this.getAvailability(this.selectedTimezone, moment(new Date()).format('yyyy-MM-DD'), lastDates.last);
    }else{

      this.getAvailability(this.selectedTimezone, lastDates.first, lastDates.last);
    }
   
  }
  /**
   * @method selectTimeSlot
   * @description Update the selected time
   * @param timeSlot String 
   */
  selectTimeSlot(timeSlot:{start_time:string}) {
    this.angulartics2Segment.eventTrack('Available Time Slot Selected', null);
    this.chooseAnotherSlot = false;
    this.selectedTimeSlot = timeSlot;
  }
  /**
   * @method confirmAvailability
   * @description Confirming the slot selected 
   * 
   */
  confirmAvailability() {
    this.angulartics2Segment.eventTrack('Schedule Call Confirm CTA Clicked ', null);
    this.chooseAnotherSlot = false;
    this.validError = false;
    if (this.selectedTimeSlot && this.selectedDate && this.selectedTimezone) {
      let availabilitySlot:AvailabilitySlot = {
        selectedTime: this.selectedTimeSlot,
        selectedDate: this.selectedDate,
        timezone: this.selectedTimezone
      }
     // sessionStorage.setItem('availabilitySlot', JSON.stringify(availabilitySlot))
     this.commonService.availabilityObject = availabilitySlot;
      if (this.selectedTimeSlot && this.selectedDate) {
        this.loaderService.show();
        let isStore = false;
        if(this.appDataService.urlParameters.store){
          isStore = true;
        }

        this.schedulerService.checkAvailability( this.callType, moment(this.selectedDate).format('yyyy-MM-DD'), this.selectedTimeSlot.start_time, this.selectedTimezone,isStore,  this.appDataService.urlParameters.generic_id, this.appDataService.urlParameters.email).subscribe(res => {
          this.loaderService.hide();
          if(res && res.booking && res.booking.id )  {
          this.commonService.start_time_in_all_timezone = res.booking.start_time_in_all_timezone;
          
          console.log('confirm obj',res);
          this.commonService.bookingId = res.booking.id;
          this.router.navigate(['schedule-form']);
        }else{
          this.chooseAnotherSlot = true;
        }
        }, error => {
          this.chooseAnotherSlot = true;
          this.loaderService.hide();
        })
      }
    } else {
      this.validError = true;
    }
  }
  /**
   * @method close
   * @description cancel or reset the calendar state
   */
  close() {
    // reset the data
    this.resetData();
    this.modelDate = {
      isRange: false,
      singleDate: {
        date: {
          year: this.selectedDate.getFullYear(),
          month: this.selectedDate.getMonth() + 1,
          day: this.selectedDate.getDate()
        }
      }

    }
  }
  /**
  * @method resetData
  * @description reset the calendar state
  */
  resetData() {
    //reset 
    this.selectedTimezone = moment2.tz.guess();
    this.selectedDate = new Date();
    this.setDateShow();
    this.selectedTimeSlot = null;
    this.getInitAvailability();
  }

  /**
   * @description setting days time slots from month's slots
   */
  setTimeSlots(currentDate: Date) {
    this.slots = [];
    let _currentDate = moment(currentDate).format('yyyy-MM-DD');
    let _index = this.monthTimeSlot.findIndex(res => {
      return res.date == _currentDate;
    })
    if (_index > -1 && this.monthTimeSlot.length>0) {
      this.slots = this.monthTimeSlot[_index].time;
    this.slots =  this.slots.sort((a:any,b:any)=>{
        let d1 = new Date(a.start_time).getTime();
        let d2 = new Date(b.start_time).getTime();
        return d1 - d2;
      });
    }
  }
  /**
   * @method timezoneConversion
   * @description change the time zone
   * @param time 
   */
  timezoneConversion(time){
    return moment(time).tz(this.selectedTimezone).format('hh:mm A');
  }
  /**
   * @method setCalendarDates
   * @description enable and disable dates
   * @param slots 
   */
  setCalendarDates(monthsSlots){
    let lastDate = this._timeZoneService.getMonthsLastDate(this.calendarView);
    let selectedFirstDate;
    if(lastDate) {
      selectedFirstDate = lastDate.first;
    }
      let enabledDates = [];
      monthsSlots.map(item=>{
      let dateData =   item.date.split('-');
      let firstDate;
      if(selectedFirstDate) {
        firstDate = selectedFirstDate.split('-');
      } else {
        firstDate = monthsSlots[0].date.split('-');
      }
      // todo check added in last 
      if(Number(dateData[1]) == Number(firstDate[1])){
        enabledDates.push({
          year: Number(dateData[0]), month:Number(dateData[1]), day:Number(dateData[2])
        })
      }
      })

    let d = new Date(); // Date object for Disable the calendar
    d.setDate(d.getDate() - 1);
    this.myDatePickerOptions = {
      dateRange: false,
      dateFormat: 'dd.mm.yyyy',
      disableUntil: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      },
      disableSince: {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      },
      enableDates:enabledDates,
      monthSelector	:true,
      disableHeaderButtons : false,
      inline: true
    };

  }

  minHourFormat(min){
    if(min){
     let _min = Number(min);
     if(_min>59){
      let hour = _min / 60;
      let result = hour + ' Hour(s)';
      if(_min % 60!=0){
        result += ' ' + _min % 60 + ' Min(s)'
      }
      return result;
    
     }else{
       return _min + ' min(s)'
     }
    }else {
      return ' '
    }
  }

}
