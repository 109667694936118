import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderComponent } from './loader/loader.component';
import { DataService } from './services/data.service';
import { HelperService } from './services/helper.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InternationalPhoneNumberModule  } from 'ngx-international-phone-number';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { SchedulerFormComponent } from './components/schedule-form/schedule-form.component';
import { ScheduleSuccessComponent } from './components/schedule-success/schedule-success.component';
import { ScheduleHeaderComponent } from './components/schedule-header/schedule-header.component';
import { ScheduleCalenderComponent } from './components/schedule-calender/schedule-calender.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { InvalidAccessComponent } from './invalid-access/invalid-access.component';
import { UnderMaintenanceComponent } from './components/under-maintenance/under-maintenance.component';
import { FilterPipe } from './filter.pipe';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2Segment } from 'angulartics2/segment';
import { ClipboardModule } from 'ngx-clipboard';


/**
 * Created by Anil Suryavanshi on 11/05/20.
 * @module AppModule
 * @description Main Module all description
 */

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    SchedulerFormComponent,
    ScheduleSuccessComponent,
    ScheduleHeaderComponent,
    ScheduleCalenderComponent,
    NotFoundComponent,
    InvalidAccessComponent,
    UnderMaintenanceComponent,
    FilterPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule, 
    FormsModule,    
    AngularMyDatePickerModule,
    InternationalPhoneNumberModule,     
    HttpClientModule,
    ClipboardModule,
    Angulartics2Module.forRoot([Angulartics2Segment])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private dataService:DataService, private helperService: HelperService){
    dataService.setInComingUrlParameters(this.helperService.getUrlParamsFromUrl())
  }
 }
