import { Injectable } from '@angular/core';
import * as moment from 'moment';
/**
 * Created by Anil Suryavanshi on 13/05/20.
 */

@Injectable({providedIn:'root'})
export class TimeZoneService {

    public currentTimeZone:String;
    public timeZoneList: Array<String>;


    get getTimeZone(){
        return this.currentTimeZone;
    }

     addTimes (startTime, endTime) {
        var times = [ 0, 0, 0 ]
        var max = times.length
      
        var a = (startTime || '').split(':')
        var b = (endTime || '').split(':')
      
        // normalize time values
        for (var i = 0; i < max; i++) {
          a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i])
          b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i])
        }
      
        // store time values
        for (var i = 0; i < max; i++) {
          times[i] = a[i] + b[i]
        }
      
        var hours = times[0]
        var minutes = times[1]
        var seconds = times[2]
      
        if (seconds >= 60) {
          var m = (seconds / 60) << 0
          minutes += m
          seconds -= 60 * m
        }
      
        if (minutes >= 60) {
          var h = (minutes / 60) << 0
          hours += h
          minutes -= 60 * h
        }
      
        return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2)
      }
        /**
     * @description for getting the last and first day of months
     * @param dateString :Date 
     * @param time meeting time
     */

      addTimeDate(dateString,time){
        let date = new Date(dateString);
        if(!time){
          time = 30
        }
        date.setMinutes(date.getMinutes()+Number(time));
        return date;
      }

    
    /**
     * @description for getting the last and first day of months
     * @param currentDate :Date 
     */
    getMonthsLastDate(currentDate:Date= new Date()){
        // let y = currentDate.getFullYear(), m = currentDate.getMonth();
        // let firstDay = new Date();
        // firstDay.setFullYear(y, m, 1); // 1 January, 14
        // let lastDay = new Date();
        // lastDay.setFullYear(y, m + 1, 0);
        return {
           
            first: moment(currentDate).startOf('month').format('yyyy-MM-DD'),
            last:moment(currentDate).endOf('month').format('yyyy-MM-DD')

        }
    }


}