import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { Constants} from '../utility/constants'
import {HttpRequests} from './http-requests.service'
import { AvailabilitySlot, Scheduling } from '../models/interface';
/**
 * Created by Anil Suryavanshi on 15/05/20.
 */
@Injectable({providedIn:'root'})
export class SchedulerService {
    availabilitySlot:AvailabilitySlot;
    scheduling:Scheduling;
    constructor(private dataService: DataService, private httpRequest: HttpRequests){

    }

    getSchedulerConfiguration(callType:string){
        const API = this.dataService.getServerUrl() + Constants.apiEndPoints.schedulerConfigurations + `?type=${callType}`
        return this.httpRequest.get(API,this.dataService.getRequestGenericOptionArgs() )

    }

    getAvailability( timezone,startDate,endDate,callType:string, isStore:boolean,email?:string,generic_id?:string){
        let email_check = '';
        let generic_id_check = '';
        if(email){
            email_check = `&email=${email}`;
        }
        if(generic_id && callType === "kickoff" && isStore === false){
            generic_id_check = `&generic_id=${generic_id}`;
        }        
        const API = this.dataService.getServerUrl() + Constants.apiEndPoints.getAvailability + `?type=${callType}&time_zone=${timezone}&start_date=${startDate}&end_date=${endDate}&studio_store=${isStore}${email_check}${generic_id_check}`;
        return this.httpRequest.get(API, this.dataService.getRequestGenericOptionArgs())

    }

    checkAvailability(callType:string,selected_date:String,time_slot:string, time_zone:string, isStore:boolean ,generic_id?:string,email?:string){
        const API = this.dataService.getServerUrl() + Constants.apiEndPoints.bookSlot;
        let data = {
            calender_booking:{
                booking_type:callType,
                meeting_date: selected_date,
                start_time_slot:time_slot,
                time_zone: time_zone,
                studio_store: isStore
            }
        }
        if(generic_id){
            data.calender_booking['generic_id'] = generic_id;
        }
        if(email){
            data.calender_booking['email'] = email;
        }
        return this.httpRequest.post(API, data, this.dataService.getRequestGenericOptionArgs())
    }
    scheduleAvailability(bookingID,selected_date,time_slot,scheduling,type,isStore){
        const API = this.dataService.getServerUrl() + Constants.apiEndPoints.updateSlot + `/${bookingID}`;
        let names = scheduling.name.split(' ');
        let files = scheduling.attatchmets;
        let firstName = names[0];
        let secondName = '';
        if(names.length>1){
            names.splice(0,1);
            secondName = names.join(' ');
        }

        // const data = {
        //     calender_booking: {
        //         booking_type:type,
        //         client_first_name:  firstName,
        //         client_last_name:   secondName,
        //         email: scheduling.email,
        //         description:scheduling.comment,
        //         phone_no:scheduling.countryCode,
        //         company: scheduling.company,
        //         studio_store:isStore,
        //         participants: scheduling.participants,           
        //     }
        // }

        const data = new FormData();
        data.append('calender_booking[booking_type]', type);
        data.append('calender_booking[client_first_name]', firstName);
        data.append('calender_booking[client_last_name]', secondName);
        data.append('calender_booking[email]', scheduling.email);
        data.append('calender_booking[description]', scheduling.comment);
        data.append('calender_booking[phone_no]', scheduling.countryCode);
        data.append('calender_booking[company]', scheduling.company);
        data.append('calender_booking[studio_store]', isStore);
       //data.append('calender_booking[participants][]', JSON.stringify(scheduling.participants));
        // if(scheduling.participants.length > 0){
        //     scheduling.participants.forEach((item,index) => {
        //         data.append('calender_booking[participants][]', item)
        //     })
        // }
        for (var i = 0, valuePair; valuePair = scheduling.participants[i]; i++)
        for (var j in valuePair) data.append('calender_booking[participants][]['+j+']', valuePair[j]);
        if (files.length > 0) {
            files.forEach((file, index) => {
                data.append('calender_booking[attachments_attributes][][file]', file)
            });
        }
        return this.httpRequest.put(API, data, this.dataService.getRequestGenericOptionArgs())
    }
    reInvite(bookingID){
        const API = this.dataService.getServerUrl() + Constants.apiEndPoints.reinvite + `/${bookingID}`;

        return this.httpRequest.get(API, this.dataService.getRequestGenericOptionArgs())
    }
}
