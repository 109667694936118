import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { UrlParameterModel } from '../models/url-parameters-model/url-parameters-model';

/**
 * Created by Anil Suryavanshi on 11/05/20.
 */

@Injectable({providedIn:'root'})
export class DataService {
  public urlParameters: UrlParameterModel;

  constructor( public commonService: CommonService, ) {
  
    this.urlParameters = new UrlParameterModel();
  }

  public getServerUrl() {
    return environment.API_BASE_URL;
  }
  
  public storeAutoComplete(remeberMe: string) {
    localStorage.setItem('auto', remeberMe);
  }

  public getAutoComplete() {
    return localStorage.getItem('auto') ? JSON.parse(localStorage.getItem('auto')) : null;
  }

  public getRequestOptionArgs(contentType?: string, isFromUrl?: boolean, body?: any) {
    const httpOptions = {  headers:  new HttpHeaders() , body: body};
    if (contentType) {
      httpOptions.headers = httpOptions.headers.set('Content-Type', contentType);
    }
    // const user = this.getUserData();
    // if (user) {
    //   const token = this.getAuthToken();
    //   if (token) {
    //     httpOptions.headers = httpOptions.headers.set('auth-token', token);
    //   }
    // }
    return httpOptions;
  }

  public getRequestGenericOptionArgs(contentType?: string, isFromUrl?: boolean, body?: any) {
    const httpOptions = {  headers:  new HttpHeaders() , body: body};
    if (contentType) {
      httpOptions.headers = httpOptions.headers.set('Content-Type', contentType);
    }
    // else{
    //   httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
    // }
    httpOptions.headers = httpOptions.headers.set('secret-key', this.urlParameters.token);
    httpOptions.headers = httpOptions.headers.set('partner-code', 'e-ai');
    // const user = this.getUserData();
    // if (user) {
    //   const token = this.getAuthToken();
    //   if (token) {
    //     httpOptions.headers = httpOptions.headers.set('auth-token', token);
    //   }
    // }
    return httpOptions;
  }

  public underscore(key) {
    return key.replace(/([A-Z])/g, ($1) => {
      return '_' + $1.toLowerCase();
    });
  }

  setInComingUrlParameters(urlParameters:UrlParameterModel){
    if(urlParameters){
      this.urlParameters = urlParameters;
    }
  }



}
