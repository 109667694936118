import { Component } from '@angular/core';
import { LoaderService } from './services/loader.service';
/**
 * Created by Anil Suryavanshi on 11/05/20.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'builder-scheduler';   
  loader:boolean = false;
  constructor(public loaderService:LoaderService){
    
  }


}
