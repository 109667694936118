<div class="bcl-schedule-header"> <!--Header Part Calls-->
    <div class="bcl-circle-box">
        <ul>
            <li class="bcl-phn-icon"> 
                <img class="bcl-main-logo" src="assets/images/bcl-phn-icon.svg" />
            </li>
            <li class="bcl-user-placeholder">
                <img class="bcl-main-logo" src="assets/images/bcl-caller.jpg" />
            </li>
        </ul>
        <div class="text-center bcl-schedule-heading-wrp">
            <div class="bcl-schedule-heading bcl-common-heading">
                {{commonService.call_header}}
            </div>
            <div class="bcl-schedule-content"  [ngStyle]="{'color': commonService.description_color}" >
               {{commonService.call_description}}
            </div>
        </div>
    </div>
</div>