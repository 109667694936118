<div class="bcl-main-work">
    <div class="second-screen">
        <!--second screen here-->
        <div class="bcl-main-heading bcl-common-heading">
            Enter your details
        </div>
        <div class="bcl-main-from">
            <form #schedulerForm="ngForm">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="firstName">Name <sup>*</sup></label>
                            <div class="common-input-box sa-name-box">
                                <input id="name" type="text" name="name" [(ngModel)]="scheduling.name"
                                    placeholder="Your name?" required="" autocomplete="off" maxlength="100"
                                    pattern="[a-zA-Z0-9 ]+" class="common-input" #name="ngModel">
                            </div>
                            <div *ngIf="name.touched && name.invalid" class="invalid-feedback"> Only alphanumeric
                                characters are allowed</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="email_bx">Emails <sup>*</sup></label>
                            <div class="common-input-box sa-email-box">
                                <input id="email_bx" placeholder="You@example.com" type="email" name="email"
                                    [(ngModel)]="scheduling.email" maxlength="100"
                                    pattern="^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{1,30})+$" required=""
                                    autocomplete="off" #email="ngModel" class="common-input">
                            </div>
                            <div *ngIf=" email.touched && email.invalid" class="invalid-feedback">Please enter valid
                                email</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="phone_num">Phone <sup>*</sup></label>
                            <div class="common-input-box bcl-phone-bx">
                                <div class="sa-phone-box">
                                    <div class="country-code-wrp" id="countryCode">
                                        <international-phone-number placeholder="Enter phone number"
                                            [(ngModel)]="scheduling.countryCode" #countryCode="ngModel"
                                            name="countryCode" [defaultCountry]="cCodeValue ? cCodeValue : 'in'"  [maxlength]="20" #countryCodeValue>
                                        </international-phone-number>
                                    </div>
                                </div>
                                <div *ngIf="countryCode.touched && countryCode.invalid" class="invalid-feedback">Please
                                    enter valid phone number</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="company_bx">Organisation name <sup *ngIf="commonService?.formRequiredFieldConfig?.company">*</sup></label>
                            <div class="common-input-box sa-company-box">
                                <input id="company_bx" #company="ngModel" placeholder="Where do you work?" type="text"
                                    name="company" [(ngModel)]="scheduling.company" maxlength="100" 
                                    [required]="commonService?.formRequiredFieldConfig?.company"
                                    autocomplete="off" class="common-input">
                            </div>
                            <div *ngIf="company.touched && company.invalid" class="invalid-feedback">Please enter
                                organisation name</div>

                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="showDescription">
                        <div class="form-group m0">
                            <label for="product_bx">{{commonService.description_text}} <sup *ngIf="commonService?.formRequiredFieldConfig?.product_bx">*</sup></label>
                            <div class="common-input-box sa-product-box">
                                <textarea id="product_bx" name="product_bx" [(ngModel)]="scheduling.comment"
                                    #product_bx="ngModel" placeholder="Add details about your app/project idea"
                                    class="common-input" maxlength="253" 
                                    [required]="commonService?.formRequiredFieldConfig?.product_bx"></textarea>
                            </div>
                            <div *ngIf="product_bx.touched && product_bx.invalid" class="invalid-feedback">Please enter your product description</div>

                        </div>
                        <div class="file-attachments-wrp d-flex align-items-center">
                            <ul *ngIf="scheduling.attatchmets.length > 0" class="d-flex m-0 p-0">
                                <li *ngFor="let file of scheduling.attatchmets;let i = index"><img src="assets/images/attatchment-icon.svg" /> <span class="ml-1 mr-1">{{file.name}}</span> <span class="pointer-cls" (click)="removeAttatchment(i)"><img src="assets/images/remove-attatch.svg"></span></li>
                            </ul>
                            <div class="file-attatch-main">
                                <label class="mb-0">
                                    <input type="file" name="attatchmets" (change)="onFileChange($event)" multiple accept="image/*,.doc,.docx,.xls,.xlsx,.pdf,.odt,.csv" />
                                    <span *ngIf="scheduling.attatchmets.length === 0"><img src="assets/images/attatchment-icon.svg" /> Attach File (Max 5mb)</span> <img class="ml-1" src="assets/images/add-new-attatch.svg" />
                                </label>
                            </div>
                        </div>
                        <div class="invalid-group" *ngIf="attatchmetsError.length > 0">
                            <div class="invalid-feedback" *ngFor="let error of attatchmetsError">{{ error.error}}</div>
                        </div>
                    </div>
                </div>
                <div class="new-participant-row"> <!--Enter Participants Html start-->
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="heading-participants">Add Participants</div>
                        </div>
                    </div>
                    	<ng-template ngFor let-participant [ngForOf]="form.participants" let-index="index" let-isLast="last">
                       <div class="row pos-rel">
                        <span (click)="removeParticipant(participant.id)" class="add-more-btn delete-row"></span>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="firstName">Name <sup>*</sup></label>
                                <div class="common-input-box sa-name-box">
                                    <input id="name" type="text" name="name_{{ participant.id }}" [(ngModel)]="participant.name"
                                        placeholder="Your name?" required="" autocomplete="off" maxlength="100"
                                        pattern="[a-zA-Z0-9 ]+" class="common-input" #name="ngModel">
                                </div>
                                <div *ngIf="name.touched && name.invalid" class="invalid-feedback"> Only alphanumeric
                                    characters are allowed</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="email_bx">Email <sup>*</sup></label>
                                <div class="common-input-box sa-email-box">
                                    <input id="email_bx" placeholder="You@example.com" type="email" name="email_{{ participant.id }}"
                                        [(ngModel)]="participant.email" maxlength="100"
                                        pattern="^\w+(?:[\.-]\w+)*@\w+(?:[\.-]\w+)*(?:\.\w{1,30})+$" required=""
                                        autocomplete="off" #email="ngModel" class="common-input">
                                </div>
                                <div *ngIf=" email.touched && email.invalid" class="invalid-feedback">Please enter valid
                                    email</div>
                            </div>
                        </div>
                        
                    </div>
                    </ng-template>
                    <div class="add-more-participants">
                        <div *ngIf="form.participants.length < 5" class="add-more-btn" (click)= "addParticipant()">
                            Add more participants
                        </div><!--add click event here-->
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="bcl-common-btn-group">
    <div class="error-warning"><!--empty div for handle flex ui-->
        <div *ngIf="message">
            {{message}}
        </div>
    </div>
    <div class="bcl-btn-grp">
        <button (click)="cancelCall()" class="bcl-common-button bcl-grey-btn">Back</button>
        <!--for second screen "Back"-->
        <!--for third screen not visible-->
        <button (click)="schedulerForm.valid && schedule()" [disabled]="schedulerForm.invalid"
            class="bcl-common-button bcl-green-btn bcl-confirm-btn">Schedule</button>
        <!--for second screen "Schedule"-->
        <!--for third screen "Resend Invite"-->
    </div>
</div>
