import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SchedulerService } from 'src/app/services/scheduler.service';
import { LoaderService } from 'src/app/services/loader.service';
import { TimeZoneService } from 'src/app/services/timezone.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { DatePipe } from '@angular/common';
import { Scheduling, AvailabilitySlot, Participant } from 'src/app/models/interface';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.scss'],
  providers: [DatePipe],
})
export class SchedulerFormComponent implements OnInit {
  message:string = '';
  scheduling : Scheduling = {
    name: '',
    email: '',
    countryCode: '',
    comment: '',
    company: '',
    participants: [],
    attatchmets: []
  };
  attatchmetsError = [];
  store:Boolean = false;
  showDescription:Boolean = true;
  public form: {
    participants: Participant[];
  };

  @ViewChild('countryCodeValue', { static: true }) countryCodeValue;
  availabilityObj: AvailabilitySlot;
  cCodeValue = '';
  constructor(private router: Router,
    private _schedulerService: SchedulerService,
    private _loaderService: LoaderService,
    private timezoneService: TimeZoneService,
    public commonService:CommonService,
    private appDataService: DataService,
    private datePipe: DatePipe) {
      this.form = {
        participants: []
      };

      // Add an initial participant form-entry.
     // this.addParticipant();
     }

      // I add a new participant record to the form-model.
  public addParticipant() : void {
    this.form.participants.push({
      id: Date.now(), // <--- uniqueness hook.
      name: "",
      email: "",
    });

  }

  public removeParticipant(id) : void {
   let index =  this.form.participants.findIndex(item=>{
      return id == item.id
    });

    if(index>-1){
      this.form.participants.splice(index,1);
    }

  }


  ngOnInit(): void {
    if(!this.appDataService.urlParameters.token){
      this.router.navigate(['invalid-access']);
    }
    if (this.commonService.availabilityObject) {
    // sessionStorage.removeItem('availabilitySlot');
     this.availabilityObj = {...this.commonService.availabilityObject}
     this.availabilityObj.selectedDate = new Date(this.availabilityObj.selectedDate);
     let selectedDateShow = moment(this.availabilityObj.selectedDate).format('dddd, MMMM DD, yyyy');
    // this.commonService.call_header = 'Product Expert Call - 30 min';
   
    this.commonService.call_description = this.commonService.start_time_in_all_timezone;
    //this.commonService.call_description = this.datePipe.transform(this.availabilityObj.selectedTime.start_time,'hh:mm a',this.availabilityObj.selectedTime.start_time.substr(this.availabilityObj.selectedTime.start_time.length-6)) + ' - '+ this.datePipe.transform(this.timezoneService.addTimeDate(this.availabilityObj.selectedTime.start_time, this.commonService.meeting_time),'hh:mm a',this.availabilityObj.selectedTime.start_time.substr(this.availabilityObj.selectedTime.start_time.length-6)) +', '+selectedDateShow;
     this.commonService.description_color= '#6C00EA';
     if (this.appDataService.urlParameters) {
      
      if(this.appDataService.urlParameters.store){
        this.store = this.appDataService.urlParameters.store;
        
     }
     if(this.appDataService.urlParameters.name){
        this.scheduling.name = this.appDataService.urlParameters.name;
     }
     if(this.appDataService.urlParameters.company){
        this.scheduling.company = this.appDataService.urlParameters.company;
     }
     if(this.appDataService.urlParameters.email){
        this.scheduling.email = this.appDataService.urlParameters.email;
     }
     if(this.appDataService.urlParameters.phone){
      this.scheduling.countryCode = this.appDataService.urlParameters.phone;
      let cCode;
      this.cCodeValue = '';
      let countries = this.countryCodeValue.countryService.getCountries();

      if (countries) {
        if (this.appDataService.urlParameters.phone.includes('+')) {
         let _cCode = this.appDataService.urlParameters.phone.split('+')[1];
         if(_cCode && _cCode.includes('-'))
          cCode =  _cCode.split('-')[0];
        }
        for (let i = 0; i < countries.length; i++) {
          if (cCode === countries[i].dialCode) {
            this.cCodeValue = countries[i].countryCode;
          }
        }
      }
     }

     if(this.commonService.callType == 'kickoff'){
      this.scheduling.comment = 'Agenda : Kick off meeting- Team introductions and Revisiting Build Card';
     
     }else{
      this.showDescription = !this.store 
      // set project idea from querystring
      if(this.appDataService.urlParameters.project_idea){
        this.scheduling.comment = this.appDataService.urlParameters.project_idea;
      }
     }
    }
    }
  }


  /**
   * @method cancelCall
   * @description back to previous page
   */


  cancelCall() {
    this.router.navigate(['availability'])
  }



  /**
    * @method schedule
    * @description Schedule the time slots.
    */
  schedule() {
    if(this.form.participants.length>0){
      this.form.participants.forEach(item => {
        this.scheduling.participants.push({name: item.name, email: item.email});
      })
    }
   
    let data = {
      scheduling: this.scheduling
    }
    this._schedulerService.scheduling = {...this.scheduling};
    //sessionStorage.setItem('contactInfo', JSON.stringify(this.scheduling));
    // this.router.navigate(['schedule-success'], { replaceUrl: true })
    // console.log(data)
    // return;
    if(this.commonService.bookingId){
      this._loaderService.show()
      let isStore = false;
      if(this.appDataService.urlParameters.store){
        isStore = true;
      }
      this._schedulerService.scheduleAvailability(this.commonService.bookingId,this.availabilityObj.selectedDate,this.availabilityObj.selectedTime.start_time,this.scheduling,this.commonService.callType,isStore)
        .subscribe(res => {
          this._loaderService.hide();
          if(res.message == 'success'){
            if(res.data.calenderbooking && res.data.calenderbooking.user && res.data.calenderbooking.user.first_name)
            this.commonService.userName = res.data.calenderbooking.user.first_name + ' '+ res.data.calenderbooking.user.last_name;
            this.commonService.location =  res.data.calenderbooking.location;
            this.router.navigate(['schedule-success'], { replaceUrl: true })
          }else{
            this.message = 'Please go back and select another slot.'
          }
        }, error => {
          this._loaderService.hide();
        })
    }else{
      this.message = 'Please go back and try again.'
    }

  }

  onFileChange(event){
    this.attatchmetsError = [];
    if(this.scheduling.attatchmets.length + event.target.files.length > 10){
      this.attatchmetsError.push({'error': 'Maximum 10 files can be uploaded in one go'});
      console.log(this.attatchmetsError);
      return;
    }
    for (let i = 0; i < event.target.files.length; i++) { 
      let name = event.target.files[i].name;
      let type = event.target.files[i].type;
      let size = event.target.files[i].size;
      let modifiedDate = event.target.files[i].lastModifiedDate;
      if(Math.round(size / 1024) <= 5000){
        this.scheduling.attatchmets.push(event.target.files[i])
      }else{
        this.attatchmetsError.push({'error': `${name} file is greater than 5 MB`});
      }
      // console.log ('Name: ' + name + "\n" + 
      //   'Type: ' + type + "\n" +
      //   'Last-Modified-Date: ' + modifiedDate + "\n" +
      //   'Size: ' + Math.round(size / 1024) + " KB");
    }
      // let files = this.scheduling.attatchmets;
      // let attatchmetsData = new FormData();
      // if (files.length > 0) {
      // files.forEach((file, index) => {
      // attatchmetsData.append('['+index+'][file]', file);
      // });
      // } 
      // const data = {
      // calender_booking: {
      // attachments_attributes : attatchmetsData
      // }
      // }
      // console.log(data);
  }
  removeAttatchment(index){
    this.scheduling.attatchmets.splice(index,1);
    console.log(this.scheduling.attatchmets);
  }
}
