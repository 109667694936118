import { JsonObject, JsonProperty } from 'json2typescript'
/**
 * Created by Anil Suryavanshi on 11/05/20.
 */
@JsonObject
export class UrlParameterModel{
@JsonProperty('app_name',String,true)
app_name: string = null;
@JsonProperty('call_type',String,true)
call_type: string = null;
@JsonProperty('token',String,true)
token: string = null;
@JsonProperty('project_idea',String,true)
project_idea: string = null;
@JsonProperty('generic_id',String,true)
generic_id: string = null;
@JsonProperty('name',String,true)
name: string = null;
@JsonProperty('email',String,true)
email: string = null;
@JsonProperty('phone',String,true)
phone: string = null;
@JsonProperty('company',String,true)
company: string = null;
@JsonProperty('store',String,true)
store: Boolean = null;
}