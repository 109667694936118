<header id="bcl-main-header"> <!--Main Header-->
    <img class="bcl-main-logo" src="assets/images/bcl-logo.svg" />
</header>
<main id="bcl-main-schedule"> <!--Main content-->
    <div class="bcl-schedule-box">
        <app-schedule-header></app-schedule-header>
        <router-outlet></router-outlet>          
        <!--<app-schedule-button></app-schedule-button>-->   
    </div>
    <div class="bcl-loader" *ngIf="loaderService.showPageLoader|async">
        <img src="assets/images/bcl-loader.gif" />
    </div>    
</main> 
