<div class="bcl-main-work">
        <div class="bcl-first-screen" style="display:block;">
            <div class="bcl-main-heading bcl-common-heading">
                Select Date & Time
            </div>
            <div class="bcl-main-calender-section">
                <div class="bcl-calender-wrp">
                    <input angular-mydatepicker (calendarViewChanged)="calendarChange($event)"  class="datePicker" type="hidden" [(ngModel)]="modelDate"  (dateChanged)="onDateChange($event)" [options]="myDatePickerOptions"
                    />
                </div>
                <div class="bcl-calender-time-slot">
                    <div class="bcl-common-sub-heading-wrp bcl-left-right-pad">
                        <div class="bcl-common-subheading bcl-time-slog-timehead">
                            {{selectedDateShow}}
                        </div>
                        <div class="bcl-slot-timezone">
                            <!--<select [(ngModel)]="selectedTimezone" (change)="timezoneChange($event)" >
                                <option [value]="timezone.abbr"  [selected]="timezone.abbr == 'IST'" *ngFor="let timezone of timezones ">{{timezone.abbr}}</option>
                            </select>-->
                            <div class="bcl-timezone-dropdown">
                                <div class="custom-select" (click)="selectTimezoneDropDown = !selectTimezoneDropDown;searchTimeZone=''">{{selectedTimezone}}</div>
                                <div class="bcl-timezone-dropdown-wrp" *ngIf="selectTimezoneDropDown">
                                    <div class="search-input">
                                        <input [(ngModel)]="searchTimeZone" type="text" />
                                    </div>
                                    <ul>
                                        <li *ngFor="let timezone of timezones | filter:searchTimeZone" (click)="selectedTimezone = timezone;selectTimezoneDropDown = !selectTimezoneDropDown;timezoneChange($event)" [ngClass]="{'active': selectedTimezone === timezone}" title="{{timezone}}">{{timezone}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bcl-slot-book" *ngIf="slots.length>0">
                        <ul >
                           
                            <li [ngClass]="{'bcl-active':slot.start_time==selectedTimeSlot?.start_time}" *ngFor="let slot of slots"  (click)="selectTimeSlot(slot)" >{{ timezoneConversion(slot.start_time) }} </li>                                                
                        </ul>
                    </div>
                    <span class="no-slots" *ngIf="slots.length == 0" >No slots available for selected date.</span>
                </div>
            </div>
        </div>
    </div>
    <div class="bcl-common-btn-group on-mobile-view-btn-grp">
        <div class="error-warning on-mobile-warning">
            <div *ngIf="validError">
                Please select a time slot.
            </div>
            <div *ngIf="chooseAnotherSlot" >
                Sorry, someone else has just snapped up that slot – can you pick another one?
            </div>
        </div>
        <div class="bcl-btn-grp for-mobile-only-btn">    
            <button (click)="close()" class="bcl-common-button bcl-grey-btn">Reset</button><!--for second screen "Back"--><!--for third screen not visible-->
            <button  (click)="confirmAvailability()" class="bcl-common-button bcl-green-btn bcl-confirm-btn">Confirm</button><!--for second screen "Schedule"--><!--for third screen "Resend Invite"-->
        </div>    
    </div>
