import { Component, OnInit, Input } from '@angular/core';
import { SchedulerService } from 'src/app/services/scheduler.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
/**
 * Created by Anil Suryavanshi on 11/05/20.
 */
@Component({
  selector: 'app-schedule-header',
  templateUrl: './schedule-header.component.html',
  styleUrls: ['./schedule-header.component.scss']
})
export class ScheduleHeaderComponent implements OnInit {
call_type:string;
 description_color: string;

  public callHeader:String = 'Product Expert Call - 30 min(s)';
  public callDescription: String = 'Speak to a product expert who will help you spec your perfect app or project.';

  constructor(public commonService:CommonService) {
    
  }
  ngOnInit(): void {
   
  }

  ngOnDestroy(){
  }

 

}
